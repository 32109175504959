'use client';

import Error from '@/components/Error';

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <html lang='en'>
      <body>
        <Error error={error} reset={reset} />;
      </body>
    </html>
  );
}
